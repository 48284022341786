.swiper-container {
  .swiper {
    margin-top: 3.13rem;

    .swiper-slide {
      padding-bottom: 3rem;
      
      display: flex;
      justify-content: center;
    }

    .swiper-button-prev, .swiper-button-next {
      color: #F26522;
    }
  }
}

@media (max-width: 399px) {
  .swiper-container {
    .swiper {
      .swiper-button-prev, .swiper-button-next {
        display: none;
      }
    }
  }
}